/** @format */

import HiddenSideBar from "./hiddenSideBar";
import { useState } from "react";
import { FaBars } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const scrollToSection = (sectionId) => {
        if (location.pathname !== "/") {
            navigate("/"); // Redirect to main page
        } else {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    return (
        <div className="fixed top-0 left-0 right-0 z-50 bg-white">
            <HiddenSideBar hidden={!sidebarOpen} toggleState={toggleSidebar} />
            <div className="flex flex-row justify-between h-12 mx-4 md:mx-20 items-center mt-4">
                <div className="md:hidden">
                    <button
                        onClick={toggleSidebar}
                        className="flex items-center text-gray-600 hover:bg-gray-100 py-2 px-2 rounded-md"
                    >
                        <FaBars size={24} />
                    </button>
                </div>
                <div>
                    <a
                        href="/"
                        className="text-accent font-semibold text-lg font-inter hidden md:block"
                    >
                        AM
                    </a>
                </div>
                <div className="space-x-14 font-medium font-inter flex flex-row items-center">
                    <span
                        onClick={() => scrollToSection("about-section")}
                        className="text-black hidden md:block cursor-pointer hover:text-accent"
                    >
                        About
                    </span>
                    <span
                        onClick={() => scrollToSection("work-section")}
                        className="text-black hidden md:block cursor-pointer hover:text-accent"
                    >
                        Work
                    </span>
                    <span
                        onClick={() =>
                            scrollToSection("work-experience-section")
                        }
                        className="text-black hidden md:block cursor-pointer hover:text-accent"
                    >
                        Experience
                    </span>
                    <span
                        onClick={() => navigate("/projects")}
                        className="text-black hidden md:block cursor-pointer hover:text-accent"
                    >
                        Projects
                    </span>
                    <a
                        href="https://drive.google.com/file/d/17YvHDcPcEwWuw0qiO3depKxmqU36A4mS/view?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="space-y-2"
                    >
                        <button className="bg-accent h-8 w-28 rounded-xl">
                            <span className="text-white">Resume</span>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}
