

export default function WorkExperience() {
    return (
        <div id='work-experience-section' className="flex flex-col mx-8 md:ml-[20%] pt-20">
            <div className="pb-10 space-y-1">
                <div className="inline-block space-y-1">
                    <span className="font-semibold text-3xl">Work Experience</span>
                    <div className="h-1 bg-accent w-full"></div>
                </div>
            </div>
            <div className="space-y-16">
                <div className="flex flex-col sm:flex-row items-start">
                    <span className="text-[#656565] text-base w-44 items-center flex">Jun 2024 - Jul 2024</span>
                    <div>
                        <span className="font-inter font-medium text-xl">
                            Interned at
                            <a className="text-accent hover:text-black" href="https://www.linkedin.com/company/marchopartners"> Marcho Partners</a>
                        </span>
                        <p className="md:w-96 text-[#323232] font-normal pt-2">
                        An internship at a hedge fund in London where I gathered market research and created a presentation on the digital payments industry. Then created a website where people can view stocks and their vibes.  
                        </p>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start">
                    <span className="text-[#656565] text-base w-44">Jun 2023 - Jul 2023</span>
                    <div>
                        <span className="font-inter font-medium text-xl">Interned at <a className="text-accent hover:text-black" href="https://www.linkedin.com/company/lone-star-funds/">Lone Star Funds</a></span>
                        <p className="md:w-96 text-[#323232] font-normal pt-2">
                        An internship at a private equity firm in London where I created a presentation alongside other 2 interns about the impacts of AI in the private equity industry.
                        </p>
                    </div>
                </div>
                {/* <div className="pb-20"></div> */}
            </div>
        </div>
    )
}