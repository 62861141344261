import { FaChevronDown } from "react-icons/fa6";
import { useState, useEffect } from 'react';

import { TypeAnimation } from 'react-type-animation';

const TypedSkills = () => {
    return (
        <span>
            <TypeAnimation
                sequence={[
                'Backend Developer',
                1000,
                    'UI/UX Designer',
                    1000,
                    'Mobile App Developer',
                    1000
                ]}
                wrapper="span"
                speed={20}
                className="text-white text-base font-semibold font-inter inline-block bg-gradient-to-r from-accent to-blue-500 px-2.5 py-1 rounded-2xl"
                repeat={Infinity}
            />
        </span>
    );
  };

export default function About() {

    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setOpacity(1);
            } else {
                setOpacity(0);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    /// This is a comment
    
    return (
        // <div id="about-section" className="flex flex-col pt-20 mx-8 sm:ml-[20%] pb-5 sm:pb-40">
        <div id="about-section" className="flex flex-col pt-10 mx-8 sm:ml-[20%] pb-5 sm:pb-40">
            <TypedSkills />
            <div className="pt-10"></div>
            <div className="flex flex-col space-y-3">
            <span className="text-accent font-semibold text-xl font-inter">Hi, my name is</span>
            {/* <TypedName /> */}
            <span className="text-black text-5xl font-semibold font-inter">Andre Maytorena</span>
            <span className="text-[#707070] text-4xl font-inter pt-1">Business Owner & Full Stack Developer</span>
            </div>
            
            <p className="sm:w-96 font-medium text-[#484848] pt-16">
                I’m a software developer who is passionate 
                for founding start-ups in a wide range of industries,
                currently working @
                <a href="https://www.instagram.com/matche.app/" className="text-accent hover:text-black"> Matche</a>.
            </p>
            <div className="pt-10 pb-20">
                <button onClick={() => window.location = 'mailto:andremayto@gmail.com'} className="border border-accent w-32 h-10 rounded-lg hover:bg-accent-200 transition-all">
                    <span className="text-accent font-medium">Contact Me</span>
                </button>
            </div>

            <div className="bg-accent h-2 w-2/3 shadow-md"></div>
            {/* <div class="relative bg-accent h-2 w-2/3 shadow-md overflow-hidden">
                <div class="absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-50 animate-shine"></div>
            </div> */}

            <div
                    className="hidden sm:block animate-bounce absolute bottom-10 left-1/2 transform -translate-x-1/2 cursor-pointer transition-opacity duration-100"
                    style={{ opacity }}
                    onClick={() => document.getElementById('work-section').scrollIntoView({ behavior: 'smooth' })}
                >
                    <FaChevronDown size={24} color="gray" />
                </div>
        </div>
    )
}

