
export default function Work() {
    return (
        <div id='work-section' className="flex flex-col mx-8 md:ml-[20%] pt-20">
            <div className="pb-10 space-y-1">
                <div className="inline-block space-y-1">
                    <span className="font-semibold text-3xl">Work</span>
                    <div className="h-1 bg-accent w-full"></div>
                </div>
            </div>
            <div className="space-y-20">
                <div className="flex flex-col sm:flex-row items-start">
                    <span className="text-[#656565] text-base w-44 items-center flex"> <div className="bg-accent h-2 w-2 rounded-xl absolute -ml-5"></div>2024 - PRESENT</span>
                    <div>
                        <span className="font-inter font-medium text-xl">Mobile App Development Agency </span>
                        <p className="md:w-96 text-[#323232] font-normal pt-2">
                            Developer of iOS and Android applications for a wide range of clients and industries.
                        </p>
                        <div className="flex flex-row space-x-2 pt-3 pb-2">
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">Python</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">SwiftUI</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">MySQL</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">Typescript</span>
                        </div>
                        <div className="flex flex-row space-x-2">
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">PHP</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">React Native</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">Expo</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start">
                <span className="text-[#656565] text-base w-44 items-center flex"> <div className="bg-accent h-2 w-2 rounded-xl absolute -ml-5"></div>2023 - PRESENT</span>
                    <div>
                        <span className="font-inter font-medium text-xl">
                            Co-founder & Developer @ 
                            <a className="text-accent hover:text-black" href="https://www.instagram.com/matche.app/"> Matche</a>
                        </span>
                        <p className="md:w-96 text-[#323232] font-normal pt-2">
                            Developed a booking app designed to allow users to easily book tennis, padel, 
                            and football courts at major locations across London.
                            Responsibilities included developing the backend infrastructure,
                            designing the mobile app, and leading the mobile app development. 
                        </p>

                        <div className="flex flex-row space-x-2 pt-3 pb-2">
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">Python</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">SwiftUI</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">MongoDB</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">SocketIO</span>
                        </div>
                        <div className="flex flex-row space-x-2">
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">Javascript</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">HTML & CSS</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start">
                    <span className="text-[#656565] text-base w-44">2022 - 2023</span>
                    <div>
                        <span className="font-inter font-medium text-xl">Founder @ <a className="text-accent hover:text-black" href="https://www.linkedin.com/company/ghost-developments/">Ghost Developments</a></span>
                        <p className="md:w-96 text-[#323232] font-normal pt-2">
                            Founded an agency focused on the development of online technologies 
                            for B2B and B2C. These included: dashboards, websites, 
                            automation tools and softwares as a service.
                        </p>

                        <div className="flex flex-row space-x-2 pt-3">
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">Python</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">React</span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">MySQL</span>
                        </div>
                    </div>
                </div>
                {/* <div className="pb-20"></div> */}
            </div>
        </div>
    )
}