/** @format */

import StripeWebsiteImage from "./../assets/stripeimage.svg";
import { FaArrowRight } from "react-icons/fa";
import MatcheAppImage from "./../assets/matcheimage.png";
import NoctoImage from "./../assets/noctoimage2.png";
import CKMGImage from "./../assets/ckmgimage.png";
import ComEventImage from "./../assets/comevimage.png";
import GoKartImage from "./../assets/gokartimage.png";
import KV01Image from "./../assets/kv01image.png";
import GoKartDashImage from "./../assets/gokartdashimage.png";
import { useState } from "react";

export default function Projects() {
    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div className="pb-20">
            <div className="flex flex-row justify-between mx-10 lg:mx-32 pt-24">
                <div id="projects-section" className="flex flex-col">
                    <div className="pb-10 space-y-1">
                        <div className="inline-block space-y-1">
                            <span className="font-semibold text-3xl">
                                Projects Showcase
                            </span>
                            <div className="h-1 bg-accent w-full"></div>
                        </div>
                    </div>
                </div>
                <a href="" className="text-accent font-semibold">
                    View all
                </a>
            </div>
            <div className="flex flex-col md:flex-row pb-10 md:space-x-24 mx-10 lg:mx-32">
                <div className="w-full md:pb-0 pb-4">
                    <div className="flex flex-col w-full space-y-2 group pb-2">
                        <a
                            href="https://stripe-web-app.vercel.app"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="space-y-2"
                        >
                            <span className="font-medium text-xl group-hover:text-accent transition-colors cursor-pointer">
                                Stripe Dashboard Clone
                            </span>
                            <div className="w-full border border-gray-200 rounded-xl">
                                <div className="image-container rounded-xl overflow-hidden relative">
                                    <img
                                        src={StripeWebsiteImage}
                                        alt="Logo"
                                        className="w-full h-full object-cover"
                                    />
                                    <div className="overlay absolute inset-0 bg-gray-300 bg-opacity-0 group-hover:bg-opacity-50 transition-all rounded-xl flex items-center justify-center cursor-pointer">
                                        <div className="animate-bounce">
                                            <FaArrowRight
                                                className="text-accent text-3xl transform opacity-0 group-hover:opacity-100 transition-all -rotate-45"
                                                size={32}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <p className="text-[#323232] font-normal text-sm">
                        A clone of the Stripe dashboard, includes authentication
                        management, and dynamically loaded data from a REST API
                        with test data.
                    </p>
                    <div className="flex flex-row space-x-2 pt-2 pb-2">
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            React
                        </span>
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            Tailwind
                        </span>
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            Python
                        </span>
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            Vercel
                        </span>
                    </div>
                </div>
                <div className="w-full overflow-x-auto">
                    <div className="flex flex-col w-full space-y-2 group pb-2">
                        <a
                            href="https://www.matche.app"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="space-y-2"
                        >
                            <span className="font-medium text-xl group-hover:text-accent transition-colors cursor-pointer">
                                Matche Start Up
                            </span>
                            <div className="w-full border border-gray-200 rounded-xl">
                                <div className="image-container rounded-xl overflow-hidden relative">
                                    <img
                                        src={MatcheAppImage}
                                        alt="Logo"
                                        className="w-full h-full object-cover"
                                    />
                                    <div className="overlay absolute inset-0 bg-gray-300 bg-opacity-0 group-hover:bg-opacity-50 transition-all rounded-xl flex items-center justify-center cursor-pointer">
                                        <div className="animate-bounce">
                                            <FaArrowRight
                                                className="text-accent text-3xl transform opacity-0 group-hover:opacity-100 transition-all -rotate-45"
                                                size={32}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <p className="text-[#323232] font-normal text-sm">
                        A mobile app that allows users to book tennis, padel and
                        football courts in London. Developed the mobile app,
                        backend and complementary website pages.
                    </p>
                    <div className="flex flex-col md:flex-row pt-2 md:space-x-2 overflow-x-auto whitespace-nowrap">
                        <div className="flex flex-row space-x-2 pb-2">
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                                Python
                            </span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                                SwiftUI
                            </span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                                HTML
                            </span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                                CSS
                            </span>
                        </div>
                        <div className="flex flex-row space-x-2 pb-2">
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                                Javascript
                            </span>
                            <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                                MongoDB
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row pb-10 md:space-x-20 mx-10 lg:mx-32">
                <div className="w-full md:pb-0 pb-4">
                    <div className="flex flex-col w-full space-y-2 group pb-2">
                        <a
                            href="https://gokart-dashboard.vercel.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="space-y-2"
                        >
                            <span className="font-medium text-xl group-hover:text-accent transition-colors cursor-pointer">
                                Go Kart Management Dashboard
                            </span>
                            <div className="w-full border border-gray-200 rounded-xl">
                                <div className="image-container rounded-xl overflow-hidden relative">
                                    <img
                                        src={GoKartDashImage}
                                        alt="Logo"
                                        className="w-full h-full object-cover"
                                    />
                                    <div className="overlay absolute inset-0 bg-gray-300 bg-opacity-0 group-hover:bg-opacity-50 transition-all rounded-xl flex items-center justify-center cursor-pointer">
                                        <div className="animate-bounce">
                                            <FaArrowRight
                                                className="text-accent text-3xl transform opacity-0 group-hover:opacity-100 transition-all -rotate-45"
                                                size={32}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <p className="text-[#323232] font-normal text-sm">
                        Built a website during my internship at a hedgefund,
                        Marcho Partners. It allows people to view stocks and
                        their vibes.
                    </p>
                    <div className="flex flex-row space-x-2 pt-2 pb-2">
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            React
                        </span>
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            Tailwind
                        </span>
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            Postgres
                        </span>
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            Python
                        </span>
                    </div>
                </div>
                <div className="w-full md:pb-0 pb-4">
                    <div className="flex flex-col w-full space-y-2 group pb-2">
                        <a
                            href="https://kv-01-website.vercel.app/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="space-y-2"
                        >
                            <span className="font-medium text-xl group-hover:text-accent transition-colors cursor-pointer">
                                KV-01 - Stocks Website
                            </span>
                            <div className="w-full border border-gray-200 rounded-xl">
                                <div className="image-container rounded-xl overflow-hidden relative">
                                    <img
                                        src={KV01Image}
                                        alt="Logo"
                                        className="w-full h-full object-cover"
                                    />
                                    <div className="overlay absolute inset-0 bg-gray-300 bg-opacity-0 group-hover:bg-opacity-50 transition-all rounded-xl flex items-center justify-center cursor-pointer">
                                        <div className="animate-bounce">
                                            <FaArrowRight
                                                className="text-accent text-3xl transform opacity-0 group-hover:opacity-100 transition-all -rotate-45"
                                                size={32}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <p className="text-[#323232] font-normal text-sm">
                        Built a website during my internship at a hedgefund,
                        Marcho Partners. It allows people to view stocks and
                        their vibes.
                    </p>
                    <div className="flex flex-row space-x-2 pt-2 pb-2">
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            React
                        </span>
                        <span className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-sm">
                            Tailwind CSS
                        </span>
                    </div>
                </div>
            </div>

            <div className="flex justify-center mt-10">
                <a
                    href="/projects"
                    className="text-accent font-semibold bg-indigo-100 px-5 py-1.5 rounded-md"
                >
                    View All
                </a>
            </div>
        </div>
    );
}
