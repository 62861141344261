import { FaPython } from "react-icons/fa";
import { FaSwift } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { RiNextjsFill } from "react-icons/ri";
import { RiTailwindCssFill } from "react-icons/ri";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { SiMongodb } from "react-icons/si";
import { SiMysql } from "react-icons/si";
import { FaJava } from "react-icons/fa";
import { TbBrandSocketIo } from "react-icons/tb";
import { FaPhp } from "react-icons/fa";
import { SiExpo } from "react-icons/si";
import { SiTypescript } from "react-icons/si";

export default function Stack() {

    const images = {
        "Python": FaPython,
        "SwiftUI": FaSwift,
        "React": FaReact,
        "Next": RiNextjsFill,
        "Tailwind CSS": RiTailwindCssFill,
        "HTML": FaHtml5,
        "CSS": FaCss3Alt,
        "Javascript": IoLogoJavascript,
        "Typescript": SiTypescript,
        "React Native": FaReact,
        "Expo": SiExpo,
        "MongoDB": SiMongodb,
        "MySQL": SiMysql,
        "Java": FaJava,
        "SocketIO": TbBrandSocketIo,
        "PHP": FaPhp,
    }

    return (    
        <div>
            <div id='stack-section' className="flex flex-col mx-10 lg:mx-32">
                <div className="space-y-1">
                    <div className="inline-block space-y-1">
                        <span className="font-semibold text-3xl">My Stack</span>
                        <div className="h-1 bg-accent w-full"></div>
                    </div>
                </div>
            </div>
            <div className='flex overflow-hidden space-x-4 pb-10 pt-10'>
                <div className='flex animate-loop-scroll space-x-4'>
                    {Object.entries(images).map(([name, Icon], index) => (
                        <div key={index} className="flex flex-col items-center bg-stone-100 w-24 h-24 justify-center rounded-lg hover:bg-stone-200 hover:scale-105 transition-all">
                            <Icon className='w-16 h-16 text-black cursor-pointer px-1.5 py-2 transition-all' />
                            <span className="text-xs">{name}</span>
                        </div>
                    ))}
                </div>
                <div className='flex animate-loop-scroll space-x-4' aria-hidden="true">
                    {Object.entries(images).map(([name, Icon], index) => (
                        <div key={index} className="flex flex-col items-center bg-stone-100 w-24 h-24 justify-center rounded-lg hover:bg-stone-200 hover:scale-105 transition-all">
                            <Icon className='w-16 h-16 text-black cursor-pointer px-1.5 py-2 transition-all' />
                            <span className="text-xs">{name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
// <Icon key={index} className='w-20 h-20 text-black text-opacity-50 cursor-pointer hover:bg-black hover:bg-opacity-30 px-1.5 py-2 transition-all rounded-md' />