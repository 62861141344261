import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {
    return (
        <div>
            <div className="h-0.5 bg-gray-100 w-full"></div>
            <footer className="flex md:flex-row flex-col items-center md:justify-between pt-8 pb-10 md:mx-32 md:items-end">
                <div className="flex flex-col space-y-4 md:items-start items-center">
                    <div className="flex flex-row items-center space-x-4">
                        <a href="https://github.com/andremaytorena" target="_blank" rel="noopener noreferrer" className='space-y-2'><FaGithub className="text-gray-600 hover:text-gray-900 transition-colors" size={26}/></a>
                        <a href="https://www.linkedin.com/in/andre-maytorena-7260212a5" target="_blank" rel="noopener noreferrer" className='space-y-2'><FaLinkedin className="text-gray-600 hover:text-gray-900 transition-colors" size={26}/></a>
                        <a href="https://x.com/andremaytorena" target="_blank" rel="noopener noreferrer" className='space-y-2'><FaXTwitter className="text-gray-600 hover:text-gray-900 transition-colors" size={26}/></a>
                    </div>
                    <p className="text-gray-800 text-sm">© Andre Maytorena 2024</p>
                </div>
                <div className="flex flex-col space-y-4 md:items-end items-center">
                    <span className="text-gray-800 font-medium text-sm pt-4">Email: <span className="text-gray-500 font-semibold cursor-pointer hover:text-accent">andremayto@gmail.com</span></span>
                    <p class="text-gray-700 text-sm text-center">
                        This website was developed with 
                        <span class="text-accent"> React </span> 
                        and 
                        <span class="text-accent"> Tailwind CSS</span>.
                    </p>
                </div>
            </footer>
        </div>

    )
}