/** @format */
import Header from "../components/header";
import About from "../sections/about";
import Work from "../sections/work";
import WorkExperience from "../sections/workexperience";
import Projects from "../sections/projectsv2";
import Stack from "../sections/stack";
import Footer from "../components/footer";

export default function Home() {
    return (
        <div>
            <Header />
            <div className="pt-20">
                <About />
                <Work />
                <WorkExperience />
                <Projects />
                <Stack />
                <Footer />
            </div>
        </div>
    );
}
