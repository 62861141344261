import React from 'react';
import { HiMiniXMark } from "react-icons/hi2";


function HiddenSideBar({ hidden, toggleState }) {
    // const location = useLocation();
    // const navigate = useNavigate();

    const scrollToAbout = () => {
        toggleState();
        const workSection = document.getElementById('about-section');
        workSection.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToWork = () => {
        toggleState();
        const workSection = document.getElementById('work-section');
        workSection.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToWorkExperience = () => {
        toggleState();
        const workSection = document.getElementById('work-experience-section');
        workSection.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToProjects = () => {
        toggleState();
        const workSection = document.getElementById('projects-section');
        workSection.scrollIntoView({ behavior: 'smooth' });
    }

  return (
    <div className={`w-72 flex-col items-start pl-3 pr-4 pt-3 mb-5 bg-white fixed top-0 left-0 h-full z-40 transform ${hidden ? '-translate-x-full' : 'translate-x-0'} transition-transform duration-300 rounded-r-xl`}>
        <div>
            <button onClick={toggleState}>
                <HiMiniXMark size={28} />
            </button>

            <div className='flex justify-center pt-7'>
                <button className='flex items-center flex-col'>
                    <span className='text-accent font-semibold text-lg font-inter'>AM</span>
                    <span className='font-bold text-gray-700 text-md pt-3 pb-10 flex flex-row items-center'>Portfolio</span>
                </button>
            </div>

            <div className="w-full h-px bg-gray-300 my-auto"></div>
            <div className='pt-4 '></div>
            <button
                className={`w-full text-left rounded-md px-2 py-1.5 hover:bg-gray-200 text-base flex items-center space-x-2`}
                onClick={scrollToAbout}
            >
                <span>About</span>
            </button>
            <button
                className={`w-full text-left rounded-md px-2 py-1.5 hover:bg-gray-200 text-base flex items-center space-x-2`}
                onClick={scrollToWork}
            >
                <span>Work</span>
            </button>
            <button
                className={`w-full text-left rounded-md px-2 py-1.5 hover:bg-gray-200 text-base flex items-center space-x-2`}
                onClick={scrollToWorkExperience}
            >
                <span>Experience</span>
            </button>
            <button
                className={`w-full text-left rounded-md px-2 py-1.5 hover:bg-gray-200 text-base flex items-center space-x-2`}
                onClick={scrollToProjects}
            >
                <span>Projects</span>
            </button>

            <div className='pb-10'></div>
        </div>
    </div>
  );
}

export default HiddenSideBar;
